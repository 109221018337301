import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Video1 } from '../../../components/images/support/videos/Video1.generated';
import { Video2 } from '../../../components/images/support/videos/Video2.generated';
import { Video3 } from '../../../components/images/support/videos/Video3.generated';
import { SupportFooter } from '../../../components/SupportFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "add-a-video-"
    }}>{`Add a video 📼`}</h1>
    <p>{`This section shows how to add a video to a workflow in Canyou. You can upload
videos to your library so your team can watch them inside a workflow. `}</p>
    <p>{`Do do this follow these steps: `}</p>
    <ol>
      <li parentName="ol">{`Upload a video`}</li>
      <li parentName="ol">{`Create a form task inside a workflow`}</li>
      <li parentName="ol">{`Add the video section inside the form task`}</li>
    </ol>
    <h3 {...{
      "id": "lets-add-a-video"
    }}>{`Let's add a video`}</h3>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Videos menu and click the `}<strong parentName="li">{`Create`}</strong>{` button.`}</li>
    </ol>

    <Video1 mdxType="Video1" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Submit your video informtaion.`}</li>
    </ol>

    <Video2 mdxType="Video2" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Your video now appears in the list.`}</li>
    </ol>

    <Video3 mdxType="Video3" />
    <p>{`Great work you have now uploaded your first video 🥳`}</p>
    <p>{`Now we can add this video to a form task inside a workflow so that a member can view it`}</p>

    <SupportFooter linkTo="/support/tasks/task-types#form-task" linkText="Setup a video form task" mdxType="SupportFooter" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      